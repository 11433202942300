<template>
  <div class="cbox">
    <div class="headbar">
      <div style="float: right">
      </div>
      <div style="padding-top: 14px">
        <el-breadcrumb>
          <el-breadcrumb-item>周月报</el-breadcrumb-item>
          <el-breadcrumb-item>周月报表单项配置</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="bcontent">
      <div style="color:orangered;padding:0 10px 10px 10px">提示：姓名、日期、手机号等学生基本信息内的字段不需要在这里添加！</div>
      <el-tabs v-model="form_name" type="card" @tab-click="handleClick">
        <el-tab-pane label="周报表单配置" name="report_week">用户管理</el-tab-pane>
        <el-tab-pane label="月报表单配置" name="report_month">配置管理</el-tab-pane>
       
      </el-tabs>

      <el-form :model="firstRow" ref="ruleForm" label-width="80px">
        <el-row v-for="(item, idx) in columnList" :key="idx">
          <el-col :span="4">
            <el-form-item label="名称" prop="role_name">
              <el-input v-model.trim="item.name" placeholder="项目名称" style="width: 100%" />
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item label="类型" prop="role_name">
              <el-select v-model="item.type" placeholder="项目类型">
                <el-option label="单行输入框" value="text"></el-option>
                <el-option label="多行输入框" value="textarea"></el-option>
                <el-option label="下拉选择框" value="select"></el-option>
                <el-option label="单选框" value="radio"></el-option>
                <el-option label="多选框" value="checkbox"></el-option>
                <el-option label="图片上传" value="file"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item label="是否必填" prop="role_name">
              <el-select v-model="item.required">
                <el-option label="是" :value="1"></el-option>
                <el-option label="否" :value="0"></el-option>

              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item label="排序号" prop="role_name">
              <el-input v-model.trim="item.sort" style="width: 100%" placeholder="填数字" />
            </el-form-item>
          </el-col>

          <el-col :span="5">
            <el-form-item label="可选值" prop="role_name" v-if="item.type=='select'||item.type=='radio'||item.type=='checkbox'">
              <el-input v-model.trim="item.value" style="width: 100%" placeholder="下拉、单选、多选的值，逗号分隔" />
            </el-form-item>

            <el-form-item label="最少字数" prop="role_name" v-if="item.type=='text'||item.type=='textarea'">
              <el-input v-model.trim="item.min" type="number" style="width: 100%" placeholder="最少字数（数字）,0或空不限制" />
            </el-form-item>

            <el-form-item label="最大张数" prop="role_name" v-if="item.type=='file'">
              <el-input v-model.trim="item.max" type="number" style="width: 100%" placeholder="最多几张,0或空不限制" />
            </el-form-item>




          </el-col>
          <el-col :span="4">
            <el-form-item label="填写说明" prop="role_name">
              <el-input v-model.trim="item.desc" style="width: 100%" placeholder="提示用户该怎么填选" />
            </el-form-item>
          </el-col>


          <el-col :span="1">
            <span @click="removeIdx(idx)" style="line-height:40px;color:orangered; margin-left:10px;cursor: pointer;"><i
                class="el-icon-remove-outline"></i></span>
          </el-col>

        </el-row>

      </el-form>

      <div style="text-align: right; padding-right:50px">
        <el-button type="primary" icon="el-icon-plus" size="small" @click="addNew()">添加项</el-button>


        <el-button type="primary" icon="el-icon-check" size="small" @click="saveForm()">保存</el-button>

      </div>


    </div>





  </div>
</template>
  
  <script>
import util from "../../utils/util"
export default {
  data() {
    return {
      dialogShow: true,
      columnList: [],
      form_name: "report_month",
      firstRow: {
        name: "",//字段名称
        type: "",//字段类型
        required: 0,//是否必填
        sort: "",//排序号
        value: "",//下拉，选择的项目，逗号或空格分隔
        desc: "",//填写说明
        real_values: "",//用户填写的值
        min:"",//最少字数


      },
      rowidx: -1,
    };
  },
  mounted() {

    this.getColumn()
  },
  methods: {
    handleClick(){
      this.columnList=[];
      this.getColumn()
    },
    addNew() {
      this.columnList.push({
        ...(this.firstRow)
      })
    },
    getColumn() {
      this.$http.post("/api/report_formconfig_info", { form_name: this.form_name }).then(res => {

        if (res.data.length == 0) {
          this.columnList.push({
            ...(this.firstRow)
          })
        } else {
          this.columnList = res.data
        }
      })
    },
    removeIdx(idx) {
      this.columnList.splice(idx, 1)
    },

    formAdd() {
      this.dialogForm = true;
      this.modalTitle = "添加";
      this.formData = {};
    },
    saveForm() {
      let data = JSON.parse(JSON.stringify(this.columnList))
      data = data.sort((a, b) => { return a.sort - b.sort })
      this.$http.post("/api/report_formconfig_save", { form_name: this.form_name, content_json: data }).then(res => {
        this.$message.success("保存成功")
        this.getColumn()
      })
    }

  },

};
</script>
  
  <style lang="less" scoped>
  </style>